import { ChannelTypeEnum, useCountries } from '../../api'
import React, { useEffect, useState } from 'react'
import {
  Body5,
  Body6,
  Box,
  Button,
  Checkbox,
  DateTimePicker,
  Dialog,
  DialogClose,
  RadioGroup,
  RadioItem,
  Space,
  TextLink
} from '@sefar/design-system'
import { NewsArticleSettings } from './news-details-settings'
import { convertDateToSend, MustReadBadge } from '../../components'
import { useTranslate } from '../../hooks/useTranslate'
import { sub, getUnixTime, fromUnixTime } from 'date-fns'

export function NewsChannelDialog({
  selectedCountries,
  isMustRead = false,
  mustReadResetDate,
  selectedChannelType,
  onUpdate
}: {
  selectedCountries: string[]
  isMustRead?: boolean
  mustReadResetDate?: string | null
  selectedChannelType: ChannelTypeEnum
  onUpdate: (patch: Partial<NewsArticleSettings>) => void
}) {
  const { t } = useTranslate()
  const [dialogCountries, setDialogCountries] = useState<string[]>(
    selectedCountries || []
  )
  const [dialogChannelType, setDialogChannelType] = useState<
    ChannelTypeEnum | undefined
  >(selectedChannelType)
  const [isMarkedAsMustRead, setIsMarkedAsMustRead] = useState(isMustRead)
  const [mustReadReset, setMustReadReset] = useState<Date | undefined>(
    mustReadResetDate ? new Date(mustReadResetDate) : undefined
  )
  useEffect(() => {
    if (mustReadResetDate && !mustReadReset) {
      setMustReadReset(new Date(mustReadResetDate))
    }
  }, [mustReadResetDate])

  const { countries } = useCountries()

  useEffect(() => {
    if (isMustRead) {
      setIsMarkedAsMustRead(true)
    }
  }, [isMustRead])
  return (
    <Dialog
      onOpenChange={(open) => {
        if (!open) {
          setDialogCountries([])
          setDialogChannelType(undefined)
        } else {
          setDialogCountries(selectedCountries || [])
          setDialogChannelType(selectedChannelType)
        }
      }}
      title="News Channel"
      trigger={<TextLink>{selectedChannelType ? 'Edit' : 'Select'}</TextLink>}
      footer={
        <Space css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              const patchParams: Partial<NewsArticleSettings> = {
                field_location: dialogCountries,
                channelType: dialogChannelType
              }
              if (dialogChannelType === ChannelTypeEnum.global) {
                patchParams.field_news_must_read = isMarkedAsMustRead
                patchParams.field_news_must_read_reset_time =
                  mustReadReset instanceof Date
                    ? convertDateToSend(mustReadReset)
                    : null
              }

              onUpdate(patchParams)
            }}
            disabled={
              dialogChannelType === ChannelTypeEnum.counties &&
              !dialogCountries?.length
            }
            as={DialogClose}
          >
            Save
          </Button>
        </Space>
      }
    >
      <Space
        css={{
          maxHeight: '100%',
          pr: '$4',
          mb: '$4',
          color: '$neutral'
        }}
      >
        <Body5>To which channels do you want to publish the article?</Body5>
      </Space>

      <RadioGroup
        id="channels type"
        value={dialogChannelType}
        onValueChange={(type: ChannelTypeEnum) => {
          setDialogCountries([])
          setDialogChannelType(type)
        }}
        aria-label="channels type"
        css={{
          d: 'flex',
          flexDirection: 'column',
          gap: '$4'
        }}
      >
        <RadioItem id="global" value={ChannelTypeEnum.global} label="Global" />
        <Box
          css={{
            d: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            p: '$4',
            border: '1px solid',
            borderColor: isMarkedAsMustRead
              ? '$primaryBlue'
              : '$destructiveDisabled',
            boxShadow: '0px 4px 8px 0 RGBA(0, 0, 0, 0.04)',
            borderRadius: '$3',
            cursor:
              dialogChannelType !== ChannelTypeEnum.global
                ? 'not-allowed'
                : 'pointer',
            opacity: dialogChannelType !== ChannelTypeEnum.global ? 0.5 : 1
          }}
        >
          <Box css={{ d: 'flex', alignItems: 'center', mb: '$3' }}>
            <MustReadBadge />
            <Box css={{ ml: 'auto' }}>
              <Checkbox
                onClick={() =>
                  dialogChannelType === ChannelTypeEnum.global &&
                  setIsMarkedAsMustRead(!isMarkedAsMustRead)
                }
                checked={isMarkedAsMustRead}
                disabled={dialogChannelType !== ChannelTypeEnum.global}
              />
            </Box>
          </Box>
          <Body6 css={{ color: '$neutralLighten25', mb: '$4' }}>
            {t('field_must_read_checkmark')}
          </Body6>
          <Box
            css={{
              opacity: !isMarkedAsMustRead ? 0.2 : 1,
              pointerEvents: !isMarkedAsMustRead ? 'none' : 'initial'
            }}
          >
            <Body6 css={{ color: '$neutralLighten50', mb: '$2' }}>
              {t('field_must_read_date')}
            </Body6>
            <DateTimePicker
              id="must-read-reset"
              initialValue={mustReadReset}
              value={mustReadReset}
              onChange={(date) => {
                setMustReadReset(date)
              }}
              autoFocus={false}
              disabled={!isMarkedAsMustRead}
            />
          </Box>
        </Box>
        <RadioItem
          id="counties"
          value={ChannelTypeEnum.counties}
          label="Countries"
        />
      </RadioGroup>

      <Space
        mt="SM"
        css={{
          d: 'grid',
          gtc: 2,
          rowGap: '$4',
          columnGap: '$12',
          width: '100%',
          '@md': { gtc: 3 }
        }}
      >
        {countries?.map((country: string) => (
          <Checkbox
            key={country}
            disabled={dialogChannelType === ChannelTypeEnum.global}
            checked={dialogCountries?.includes(country)}
            onCheckedChange={(checked) =>
              setDialogCountries((prevState) =>
                checked
                  ? [...prevState, country]
                  : prevState.filter((item) => item !== country)
              )
            }
            label={country}
          />
        ))}
      </Space>
    </Dialog>
  )
}
